<template>
  <div>
    <ApplyMain ref="applymain" stepcurrent="2">
      <div slot="content" class="container">
        <div class="callout mb10" style="" id="applyNotice">
          <div class="content">
            <div id="flowNormalComBank2div">
              <span
                class="i18nlanguage"
                style=""
                data-i18n="common.lable.flow_normal_com_bank.content.six"
                >1. 公司信息请严格按照相关证件信息进行确认填写；</span
              ><br />
              <span
                class="i18nlanguage"
                style=""
                data-i18n="common.lable.flow_normal_com_bank.content.seven"
                >2.
                以下所需要上传电子版资质仅支持JPG、GIF、PNG格式的图片，大小不超过1M，且必须加盖企业彩色公章。</span
              >
            </div>
          </div>
        </div>
        <div class="m">
          <el-form
            :model="form"
            :inline-message="true"
            :rules="rules"
            size="mini"
            label-width="100px"
            class="elform"
            ref="form"
          >
            <div class="mt">
              <h4 class="title">
                <span>营业执照信息</span>
              </h4>
            </div>
            <div class="mc">
              <div class="pop-electronic-trade-license-comp">
                <div class="form-error-text">
                  <span
                    >电子营业执照为无纸化的电子执照，使用电子营业执照将提升审核时效授权获取电子营业执照</span
                  ><span
                    ><a class="link-blue" style="color: #48a7e7;">
                      授权获取电子营业执照信息</a
                    ></span
                  >
                </div>
              </div>
              <div>
                <el-form-item
                  label="执照类型"
                  label-width="185px"
                  prop="businessLicenseType"
                >
                  <el-select
                    placeholder="请选择执照类型"
                    style="width:400px"
                    v-model="form.businessLicenseType"
                  >
                    <el-option
                      v-for="item in businessLicenseTypedata"
                      :key="item.id"
                      :label="item.data_title"
                      :value="item.data_title"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="营业执照"
                  label-width="185px"
                  prop="businessLicenseImg"
                >
                  <div class="form-describe-text upload-pop-desc">
                    <span
                      >请上传清晰营业执照图片，系统识别公司信息自动进行填写，营业执照复印件需加盖公司红章扫描上传，若营业执<br />照上未体现注册资本、经营范围，请在营业执照后另行上传<a
                        target="_blank"
                        href="https://www.gsxt.gov.cn/index.html"
                        class="link-blue"
                        >企业信息公示网</a
                      >上的截图。</span
                    >
                  </div>
                  <el-upload
                    v-model="form.businessLicenseImg"
                    :multiple="false"
                    class=""
                    :limit="2"
                    :headers="myHeaders"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :action="uploadUrl"
                    name="file"
                    accept="image/png,image/gif,image/jpg,image/jpeg"
                    :file-list="businessLicenseImgfileList"
                    :on-success="busSuccess"
                    :on-error="busError"
                    :on-change="busChange"
                    :show-file-list="false"
                  >
                    <img
                      v-if="form.businessLicenseImgUrl != ''"
                      :src="form.businessLicenseImgUrl"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <div class="el-upload__tip">
                    图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持png,jpeg,gif格式，最多可上传2张
                  </div>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label="公司名称"
                  label-width="185px"
                  prop="companyName"
                >
                  <el-input
                    v-model="form.companyName"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span
                    >请按照营业执照上登记的完整名称填写，如有（），请在输入法为中文状态下输入</span
                  >
                </div>
              </div>
              <div>
                <el-form-item
                  class="formL small"
                  label-width="185px"
                  label="营业执照注册号"
                  prop="creditCode"
                >
                  <el-input
                    v-model="form.creditCode"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>请按照营业执照上的注册号进行填写</span>
                </div>
              </div>
              <div>
                <el-form-item
                  label="营业执照所在地"
                  label-width="185px"
                  class="formaddress small"
                  prop="province"
                >
                  <el-cascader
                    ref="addressCascader"
                    style="width:400px"
                    :options="city"
                    v-model="form.province"
                    @change="oncitycascader"
                    clearable
                  ></el-cascader>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>请按照营业执照上的注册地址填写</span>
                </div>
              </div>
              <div>
                <el-form-item
                  label="营业执照详细地址"
                  label-width="185px"
                  class="formaddress"
                  prop="businessLicenseAddress"
                >
                  <span>{{ businessLicensearea }}</span>
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="form.businessLicenseAddress"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>请按照营业执照上的注册详细地址填写</span>
                </div>
              </div>
              <div>
                <el-form-item
                  class="small"
                  label="成立日期"
                  label-width="185px"
                  prop="establishDate"
                >
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    v-model="form.establishDate"
                    style="width: 200px;"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL small"
                  label-width="185px"
                  label="营业期限"
                  prop="isshowbusinessend"
                >
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    v-model="form.businessStart"
                    style="width: 200px;"
                  >
                  </el-date-picker>
                  <span v-if="!form.isshowbusinessend">&nbsp;-&nbsp;</span>
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-if="!form.isshowbusinessend"
                    placeholder="选择日期"
                    v-model="form.businessEnd"
                    style="width: 200px;margin-right:10px"
                  >
                  </el-date-picker>
                  <el-checkbox
                    v-model="form.isshowbusinessend"
                    border
                    style="margin-left:10px"
                    @change="onshowbusinessend"
                  >
                    长期</el-checkbox
                  >
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label-width="185px"
                  label="注册资本"
                  prop="entRegCapital"
                >
                  <el-input
                    v-model="form.entRegCapital"
                    style="width:400px"
                    oninput="if(value.length>6)value=value.slice(0,6)"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>若注册资本非人民币，按照当前汇率换算人民币填写</span>
                </div>
              </div>
              <div>
                <el-form-item
                  label="经营范围"
                  label-width="185px"
                  class="businessScope"
                  prop="businessScope"
                >
                  <el-input
                    type="textarea"
                    style="width:400px"
                    v-model="form.businessScope"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="法人代表证件类型"
                  label-width="185px"
                  prop="legalPersonCertType"
                >
                  <el-select
                    placeholder="请选择法人代表证件类型"
                    style="width:400px"
                    v-model="form.legalPersonCertType"
                  >
                    <el-option value="身份证" label="身份证">身份证</el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="法人证件电子版"
                  label-width="185px"
                  prop="legalPersonCertImg"
                >
                  <div class="form-describe-text upload-pop-desc">
                    <span
                      >请按顺序分别上传正面（带有照片一面）和反面电子版图片，复印件请加盖开店公司红章<a
                        target="_blank"
                        href="https://www.gsxt.gov.cn/index.html"
                        class="link-blue"
                        >查看示例</a
                      ></span
                    >
                  </div>
                  <el-upload
                    class="upload-demo"
                    :headers="myHeaders"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :action="uploadUrl"
                    name="file"
                    v-model="form.legalPersonCertImg"
                    accept="image/png,image/gif,image/jpg,image/jpeg"
                    :file-list="legalPersonCertImgfileList"
                    :on-success="busSuccesslegalPersonCertImg"
                    :on-error="busError"
                    :on-change="busChangelegalPersonCertImg"
                    :show-file-list="false"
                  >
                    <img
                      v-if="form.legalPersonCertImgUrl != ''"
                      :src="form.legalPersonCertImgUrl"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <div class="el-upload__tip">
                    图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持png,jpeg,gif格式，最多可上传2张
                  </div>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label-width="185px"
                  label="法定代表人姓名"
                  prop="legalPersonName"
                >
                  <el-input
                    v-model="form.legalPersonName"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>请按照营业执照上登记的法人填写</span>
                </div>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label-width="185px"
                  label="法人代表证件号"
                  prop="legalPersonCertNO"
                >
                  <el-input
                    v-model="form.legalPersonCertNO"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL small"
                  label-width="185px"
                  label="有效期"
                  prop="isshowlegalPersonCertEnd"
                >
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    v-model="form.legalPersonCertStart"
                    style="width: 200px;"
                  >
                  </el-date-picker>
                  <span v-if="!form.isshowlegalPersonCertEnd"
                    >&nbsp;-&nbsp;</span
                  >
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-if="!form.isshowlegalPersonCertEnd"
                    placeholder="选择日期"
                    v-model="form.legalPersonCertEnd"
                    style="width: 200px;margin-right:10px"
                  >
                  </el-date-picker>
                  <el-checkbox
                    v-model="form.isshowlegalPersonCertEnd"
                    border
                    style="margin-left:10px"
                    @change="onshowlegalPersonCertEnd"
                  >
                    长期</el-checkbox
                  >
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="公司所在地"
                  label-width="185px"
                  class="formaddress small"
                  prop="provinceaddress"
                >
                  <el-cascader
                    ref="addressCascaderaddress"
                    style="width:400px"
                    :options="city"
                    v-model="form.provinceaddress"
                    @change="oncitycascaderaddress"
                    clearable
                  ></el-cascader>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="公司详细地址"
                  label-width="185px"
                  class="formaddress"
                  prop="address"
                >
                  <span>{{ addressarea }}</span>
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="form.address"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label-width="185px"
                  label="公司电话"
                  prop="companyTel"
                >
                  <el-input
                    v-model="form.companyTel"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label-width="185px"
                  label="公司紧急联系人"
                  prop="urgentContactUserName"
                >
                  <el-input
                    v-model="form.urgentContactUserName"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label-width="185px"
                  label="公司紧急联系人手机"
                  prop="urgentContactUserMobile"
                >
                  <el-input
                    v-model="form.urgentContactUserMobile"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="银行开户许可证电子版"
                  label-width="185px"
                  prop="bankOpenAccountLicenceImg"
                >
                  <div class="form-describe-text upload-pop-desc">
                    <span
                      >许可证上名称、法人需与营业执照一致，若发生变更须出具变更证明，复印件需加盖公司红章扫描上传</span
                    >
                  </div>
                  <el-upload
                    :multiple="false"
                    class="upload-demo"
                    :headers="myHeaders"
                    list-type="picture-card"
                    :on-remove="busRemovebankOpenAccountLicenceImg"
                    :action="uploadUrl"
                    name="file"
                    v-model="form.bankOpenAccountLicenceImg"
                    accept="image/png,image/gif,image/jpg,image/jpeg"
                    :file-list="bankOpenAccountLicenceImgfileList"
                    :on-success="busSuccessbankOpenAccountLicenceImg"
                    :on-error="busErrorbankOpenAccountLicenceImg"
                    :on-change="busChangebankOpenAccountLicenceImg"
                    :show-file-list="false"
                  >
                    <img
                      v-if="form.bankOpenAccountLicenceImgUrl != ''"
                      :src="form.bankOpenAccountLicenceImgUrl"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <div class="el-upload__tip">
                    图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持png,jpeg,gif格式，最多可上传1张
                  </div>
                </el-form-item>
              </div>
            </div>
            <div class="mt">
              <h4 class="title">
                <span>组织机构代码证 </span>
              </h4>
            </div>
            <div class="mc">
              <div>
                <el-form-item
                  class="formL small"
                  label-width="185px"
                  label="组织机构代码"
                  prop="organizationCertCode"
                >
                  <el-input
                    v-model="form.organizationCertCode"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span
                    >复印件需加盖公司红章扫描上传，三证合一的此处请上传营业执照电子版</span
                  >
                </div>
              </div>
              <div>
                <el-form-item
                  class="formL small"
                  label-width="185px"
                  label="组织机构代码证有效期"
                  prop="isshoworganizationCertEnd"
                >
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    v-model="form.organizationCertStart"
                    style="width: 200px;"
                  >
                  </el-date-picker
                  ><span v-if="!form.isshoworganizationCertEnd"
                    >&nbsp;-&nbsp;</span
                  >
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-if="!form.isshoworganizationCertEnd"
                    placeholder="选择日期"
                    v-model="form.organizationCertEnd"
                    style="width: 200px;margin-right:10px"
                  >
                  </el-date-picker>
                  <el-checkbox
                    v-model="form.isshoworganizationCertEnd"
                    border
                    @change="onshoworganizationCertEnd"
                    style="margin-left:10px"
                    >长期
                  </el-checkbox>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  label="组织机构代码证电子版"
                  label-width="185px"
                  prop="organizationCertImg"
                >
                  <div class="form-describe-text upload-pop-desc">
                    <span
                      >复印件需加盖公司红章扫描上传，三证合一的此处请上传营业执照电子版</span
                    >
                  </div>
                  <el-upload
                    class="upload-demo"
                    :headers="myHeaders"
                    list-type="picture-card"
                    :action="uploadUrl"
                    name="file"
                    accept="image/png,image/gif,image/jpg,image/jpeg"
                    :data="1"
                    :file-list="organizationCertImgfileList"
                    :on-success="busSuccessorganizationCertImg"
                    :on-error="busErrororganizationCertImg"
                    :on-change="busChangeorganizationCertImg"
                    v-model="form.organizationCertImg"
                    :show-file-list="false"
                  >
                    <img
                      v-if="form.organizationCertImgUrl != ''"
                      :src="form.organizationCertImgUrl"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <div class="el-upload__tip">
                    图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持png,jpeg,gif格式，最多可上传1张
                  </div>
                </el-form-item>
                <div class="btn-group t-r">
                  <el-form-item>
                    <el-button @click="onPrev()">上一步</el-button>
                    <el-button
                      class="btn"
                      @click="onsubmitForm('elForm')"
                      type="primary"
                      >下一步，完善税务及银行信息</el-button
                    >
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </ApplyMain>
  </div>
</template>
<script>
import ApplyMain from '@/components/applymain.vue';
import Cookies from 'js-cookie';
import { getCookieByName } from '@/utils/helper';
var token = getCookieByName('access_token');
import {
  companyintobusinesslicense,
  getcompanyintobusinesslicense,
  getcompanyintolegalperson,
  companyintolegalperson,
  getcompanyintoorganization,
  companyintoorganization,
  utilsbusinesslicenseocr
} from '@/api/user';
import { cityjson } from '@/utils/cityjson.js';
export default {
  name: 'contact',
  components: {
    ApplyMain
  },
  data() {
    var validatorPhone = function(rule, value, callback) {
      let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;

      if (value === '') {
        callback(new Error('请输入紧急联系人手机'));
      } else if (!phoneReg.test(value)) {
        console.log(1);
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    return {
      businessLicensearea: '',
      addressarea: '',
      city: cityjson,
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      dialogImageUrl: '',
      businessLicenseImgfileList: [],
      organizationCertImgfileList: [],
      legalPersonCertImgfileList: [],
      bankOpenAccountLicenceImgfileList: [],
      businessLicenseTypedata: [
        { id: 1, data_title: '多证合一营业执照（统一社会信用代码）' },
        { id: 2, data_title: '多证合一营业执照（非统一社会信用代码）' }
      ],
      form: {
        isshoworganizationCertEnd: false,
        isshowbusinessend: false,
        isshowlegalPersonCertEnd: false,
        companyName: '',
        businessLicenseImg: '',
        businessLicenseImgUrl: '',
        businessLicenseType: null,
        creditCode: '',
        province: '',
        businessScope: '',
        businessLicenseProvinceId: 0,
        businessLicenseProvinceName: '',
        businessLicenseCityId: 0,
        businessLicenseCityName: '',
        businessLicenseCountyId: 0,
        businessLicenseCountyName: '',
        businessLicenseAdcode: '',
        businessLicenseAddress: '',
        businessLicenseLnglatAmap: '',
        businessLicenseLnglatBaidu: '',
        establishDate: '',
        businessStart: '',
        businessEnd: '',
        entRegCapital: 0,
        provinceaddress: '',
        provinceId: 0,
        provinceName: '',
        cityId: 0,
        cityName: '',
        countyId: 0,
        countyName: '',
        adcode: '',
        address: '',
        lnglatAmap: '',
        lnglatBaidu: '',
        companyTel: '',
        urgentContactUserName: '',
        urgentContactUserMobile: '',
        bankOpenAccountLicenceImg: '',
        bankOpenAccountLicenceImgUrl: '',
        legalPersonCertType: null,
        legalPersonCertImg: '',
        legalPersonCertImgUrl: '',
        legalPersonName: '',
        legalPersonCertNO: '',
        legalPersonCertStart: '',
        legalPersonCertEnd: '',
        organizationCertImg: '',
        organizationCertImgUrl: '',
        organizationCertCode: '',
        organizationCertStart: '',
        organizationCertEnd: '',
        id: 0
      },
      rules: {
        businessLicenseType: [
          { required: true, message: '请选择执照类型', trigger: 'change' }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, message: '请输入营业执照注册号', trigger: 'blur' }
        ],
        businessLicenseAddress: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ],
        entRegCapital: [
          { required: true, message: '请输入注册资本', trigger: 'blur' },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '注册金额有误',
            trigger: 'blur'
          }
        ],
        establishDate: [
          { required: true, message: '请输入成立日期', trigger: 'blur' }
        ],
        isshowbusinessend: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let { businessStart, businessEnd } = this.form;
              if (value && businessStart == '') {
                callback(new Error('请选择正确的营业期限'));
              } else if (!value && (businessStart == '' || businessEnd == '')) {
                callback(new Error('请选择正确的营业期限'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        isshowlegalPersonCertEnd: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let { legalPersonCertStart, legalPersonCertEnd } = this.form;
              if (value && legalPersonCertStart == '') {
                callback(new Error('请选择正确的有效期'));
              } else if (
                !value &&
                (legalPersonCertStart == '' || legalPersonCertEnd == '')
              ) {
                callback(new Error('请选择正确的有效期'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        isshoworganizationCertEnd: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let { organizationCertStart, organizationCertEnd } = this.form;
              if (value && organizationCertStart == '') {
                callback(new Error('请选择正确的组织机构代码证有效期'));
              } else if (
                !value &&
                (organizationCertStart == '' || organizationCertEnd == '')
              ) {
                callback(new Error('请选择正确的组织机构代码证有效期'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        businessEnd: [{ required: true, trigger: 'blur' }],
        businessScope: [
          { required: true, message: '请输入经营范围', trigger: 'blur' }
        ],
        legalPersonCertType: [
          {
            required: true,
            message: '请选择法人代表证件类型',
            trigger: 'change'
          }
        ],
        entregstatustxt: [
          { required: true, message: '请选择登记状态', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入公司地址', trigger: 'blur' }
        ],
        province: [
          {
            type: 'array',
            required: true,
            message: '请选择省市区',
            trigger: 'blur'
          }
        ],
        legalPersonName: [
          { required: true, message: '请输入公司法人', trigger: 'blur' }
        ],
        legalPersonCertNO: [
          { required: true, message: '请输入法人证件号', trigger: 'blur' }
        ],
        companyTel: [
          { required: true, message: '请输入公司电话', trigger: 'blur' }
        ],
        urgentContactUserMobile: [
          { required: true, validator: validatorPhone, trigger: 'blur' }
        ],
        urgentContactUserName: [
          { required: true, message: '请输入公司紧急联系人', trigger: 'blur' }
        ],
        organizationCertCode: [
          { required: true, message: '请输入组织机构代码', trigger: 'blur' }
        ],
        businessLicenseImg: [
          { required: true, message: '请上传营业执照', trigger: 'blur' }
        ],
        legalPersonCertImg: [
          { required: true, message: '请上传法人证件电子版', trigger: 'blur' }
        ],
        bankOpenAccountLicenceImg: [
          {
            required: true,
            message: '请上传银行开户许可证电子版',
            trigger: 'blur'
          }
        ],
        organizationCertImg: [
          {
            required: true,
            message: '请上传组织机构代码证电子版',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    onPrev() {
      this.$router.push('/user/apply/contact');
    },
    ongetcompanyintobusinesslicense() {
      let params = {};
      getcompanyintobusinesslicense(params).then(res => {
        if (res.data.code === 1) {
          this.form.id = res.data.result.id;
          this.form.companyName = res.data.result.companyName;
          this.form.businessLicenseImg = res.data.result.businessLicenseImg;
          this.form.businessLicenseImgUrl =
            res.data.result.businessLicenseImgUrl;
          this.form.businessLicenseType = res.data.result.businessLicenseType;
          this.form.creditCode = res.data.result.creditCode;
          this.form.businessLicenseProvinceId =
            res.data.result.businessLicenseProvinceId;
          this.form.businessLicenseProvinceName =
            res.data.result.businessLicenseProvinceName;
          this.form.businessLicenseCityId =
            res.data.result.businessLicenseCityId;
          this.form.businessLicenseCityName =
            res.data.result.businessLicenseCityName;
          this.form.businessLicenseCountyId =
            res.data.result.businessLicenseCountyId;
          this.form.businessLicenseCountyName =
            res.data.result.businessLicenseCountyName;
          this.form.businessLicenseAdcode =
            res.data.result.businessLicenseAdcode;
          this.form.businessLicenseAddress =
            res.data.result.businessLicenseAddress;
          this.form.businessLicenseLnglatAmap =
            res.data.result.businessLicenseLnglatAmap;
          this.form.businessLicenseLnglatBaidu =
            res.data.result.businessLicenseLnglatBaidu;
          this.form.establishDate = res.data.result.establishDate;
          this.form.businessStart = res.data.result.businessStart;
          this.form.businessEnd = res.data.result.businessEnd;
          if (this.form.businessEnd == '长期') {
            this.form.isshowbusinessend = true;
          }
          this.form.entRegCapital = res.data.result.entRegCapital;
          this.form.businessScope = res.data.result.businessScope;
          this.form.provinceId = res.data.result.provinceId;
          this.form.provinceName = res.data.result.provinceName;
          this.form.cityId = res.data.result.cityId;
          this.form.cityName = res.data.result.cityName;
          this.form.countyId = res.data.result.countyId;
          this.form.countyName = res.data.result.countyName;
          this.form.adcode = res.data.result.adcode;
          this.form.address = res.data.result.address;
          this.form.lnglatAmap = res.data.result.lnglatAmap;
          this.form.lnglatBaidu = res.data.result.lnglatBaidu;
          this.form.companyTel = res.data.result.companyTel;
          this.form.urgentContactUserName =
            res.data.result.urgentContactUserName;
          this.form.urgentContactUserMobile =
            res.data.result.urgentContactUserMobile;
          this.form.bankOpenAccountLicenceImg =
            res.data.result.bankOpenAccountLicenceImg;
          this.form.bankOpenAccountLicenceImgUrl =
            res.data.result.bankOpenAccountLicenceImgUrl;
          this.form.province = [
            '' + res.data.result.businessLicenseProvinceId,
            '' + res.data.result.businessLicenseCityId,
            '' + res.data.result.businessLicenseCountyId
          ];
          this.businessLicensearea =
            (res.data.result.businessLicenseProvinceName === null
              ? ''
              : res.data.result.businessLicenseProvinceName) +
            (res.data.result.businessLicenseCityName === null
              ? ''
              : res.data.result.businessLicenseCityName) +
            (res.data.result.businessLicenseCountyName === null
              ? ''
              : res.data.result.businessLicenseCountyName);
          this.addressarea =
            (res.data.result.provinceName === null
              ? ''
              : res.data.result.provinceName) +
            (res.data.result.cityName === null
              ? ''
              : res.data.result.cityName) +
            (res.data.result.countyName === null
              ? ''
              : res.data.result.countyName);
          this.form.provinceaddress = [
            '' + res.data.result.provinceId,
            '' + res.data.result.cityId,
            '' + res.data.result.countyId
          ];
          this.ongetcompanyintolegalperson();
        }
      });
    },
    ongetcompanyintolegalperson() {
      let params = {};
      getcompanyintolegalperson(params).then(res => {
        if (res.data.code === 1) {
          this.form.legalPersonName = res.data.result.legalPersonName;
          this.form.legalPersonCertType = res.data.result.legalPersonCertType;
          this.form.legalPersonCertImg = res.data.result.legalPersonCertImg;
          this.form.legalPersonCertImgUrl =
            res.data.result.legalPersonCertImgUrl;
          this.form.legalPersonCertNO = res.data.result.legalPersonCertNO;
          this.form.legalPerson = res.data.result.legalPerson;
          this.form.legalPersonCertStart = res.data.result.legalPersonCertStart;
          this.form.legalPersonCertEnd = res.data.result.legalPersonCertEnd;
          if (this.form.legalPersonCertEnd == '长期') {
            this.form.isshowlegalPersonCertEnd = true;
          }
          this.ongetcompanyintoorganization();
        }
      });
    },
    ongetcompanyintoorganization() {
      let params = {};
      getcompanyintoorganization(params).then(res => {
        if (res.data.code === 1) {
          this.form.organizationCertImg = res.data.result.organizationCertImg;
          this.form.organizationCertImgUrl =
            res.data.result.organizationCertImgUrl;
          this.form.organizationCertCode = res.data.result.organizationCertCode;
          this.form.organizationCertStart =
            res.data.result.organizationCertStart;
          this.form.organizationCertEnd = res.data.result.organizationCertEnd;
          if (this.form.organizationCertEnd == '长期') {
            this.form.isshoworganizationCertEnd = true;
          }
        }
      });
    },
    onshowbusinessend(value) {
      this.form.isshowbusinessend = value;
      if (value) {
        this.form.businessEnd = '长期';
      } else {
        this.form.businessEnd = '';
      }
    },
    onshowlegalPersonCertEnd(value) {
      this.form.isshowlegalPersonCertEnd = value;
      if (value) {
        this.form.legalPersonCertEnd = '长期';
      } else {
        this.form.legalPersonCertEnd = '';
      }
    },
    onshoworganizationCertEnd(value) {
      this.form.isshoworganizationCertEnd = value;
      if (value) {
        this.form.organizationCertEnd = '长期';
      } else {
        this.form.organizationCertEnd = '';
      }
    },
    onsubmitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = this.form;
          companyintobusinesslicense(params).then(res => {
            if (res.data.code === 1) {
              this.onsavecompanyintolegalperson();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    onsavecompanyintolegalperson() {
      let params = this.form;
      companyintolegalperson(params).then(res => {
        if (res.data.code === 1) {
          this.onsavecompanyintoorganization();
        } else {
          this.$message.error(res.data.message);
        }
      });
      return;
    },
    onsavecompanyintoorganization() {
      let params = this.form;
      companyintoorganization(params).then(res => {
        if (res.data.code === 1) {
          this.$router.push('/user/apply/comnewnormalbank');
        } else {
          this.$message.error(res.data.message);
        }
      });
      return;
    },
    oncitycascader(item) {
      var labels = this.$refs.addressCascader.getCheckedNodes()[0].pathLabels;
      this.form.businessLicenseProvinceId = item[0];
      this.form.businessLicenseCityId = item[1];
      this.form.businessLicenseCountyId = item[2];
      this.form.province = item;
      this.form.businessLicenseProvinceName = labels[0];
      this.form.businessLicenseCityName = labels[1];
      this.form.businessLicenseCountyName = labels[2];
      this.businessLicensearea =
        this.form.businessLicenseProvinceName +
        ' ' +
        this.form.businessLicenseCityName +
        ' ' +
        this.form.businessLicenseCountyName +
        ' ';
    },
    oncitycascaderaddress(item) {
      var labels = this.$refs.addressCascaderaddress.getCheckedNodes()[0]
        .pathLabels;
      this.form.provinceId = item[0];
      this.form.cityId = item[1];
      this.form.countyId = item[2];
      this.form.provinceaddress = item;
      this.form.provinceName = labels[0];
      this.form.cityName = labels[1];
      this.form.countyName = labels[2];
      this.addressarea =
        this.form.provinceName +
        ' ' +
        this.form.cityName +
        ' ' +
        this.form.countyName +
        ' ';
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.businessLicenseImgfileList = fileList;
      if (response.code == 1) {
        this.form.businessLicenseImg = response.result.fileToken;
        this.form.businessLicenseImgUrl = response.result.url + '/image/256x';
        utilsbusinesslicenseocr({
          imgurl: response.result.url + '/raw'
        }).then(res => {
          this.loading = false;
          if (res.data.code == 1) {
            var obj = res.data.result;
            $.each(obj, function(key, index) {
              if (key == '注册资本') {
                that.form.entRegCapital = obj[key].replace('万元', '');
                if (that.form.entRegCapital > 6) {
                  that.form.entRegCapital = '';
                }
              } else if (key == '社会信用代码' && obj[key] != '无') {
                that.form.creditCode = obj[key];
              } else if (key == '单位名称' && obj[key] != '无') {
                that.form.companyName = obj[key];
              } else if (key == '法人' && obj[key] != '无') {
                that.form.legalPerson = obj[key];
              } else if (key == '组成形式' && obj[key] != '无') {
                //$("#entRegCapital").val(obj[key]);
              } else if (key == '成立日期' && obj[key] != '无') {
                that.form.establishDate = obj[key]
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '');
                that.form.businessStart = obj[key]
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '');
              } else if (key == '地址' && obj[key] != '无') {
                //that.form.address = obj[key];
              } else if (key == '经营范围' && obj[key] != '无') {
                that.form.businessScope = obj[key];
              } else if (key == '类型' && obj[key] != '无') {
                if (obj[key].indexOf('有限责任公司') > -1) {
                  that.form.entregtypetxt = '有限责任公司';
                  that.form.entregtypeid = that.ongetentregtype(
                    that.form.entregtypetxt
                  );
                }
              } else if (
                key == '有效期' &&
                obj[key] != '无' &&
                obj[key] != '长期'
              ) {
                that.form.businessEnd = obj[key]
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '');
              } else if (
                key == '有效期' &&
                obj[key] != '无' &&
                obj[key] == '长期'
              ) {
                that.form.businessEnd = '长期';
                that.isshowbusinessend = true;
              }
            });
          }
        });
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.businessLicenseImgfileList = [fileList[fileList.length - 1]];
      }
    },
    busSuccesslegalPersonCertImg(response, file, fileList) {
      var that = this;
      this.legalPersonCertImgfileList = fileList;
      if (response.code == 1) {
        this.form.legalPersonCertImg = response.result.fileToken;
        this.form.legalPersonCertImgUrl = response.result.url + '/image/256x';
      }
    },
    busRemovelegalPersonCertImg() {},
    busErrorlegalPersonCertImg(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChangelegalPersonCertImg(file, fileList) {
      if (fileList.length > 0) {
        this.legalPersonCertImgfileList = [fileList[fileList.length - 1]];
      }
    },
    busSuccessorganizationCertImg(response, file, fileList) {
      var that = this;
      this.organizationCertImgfileList = fileList;
      if (response.code == 1) {
        this.form.organizationCertImg = response.result.fileToken;
        this.form.organizationCertImgUrl = response.result.url + '/image/256x';
      }
    },
    busErrororganizationCertImg(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChangeorganizationCertImg(file, fileList) {
      if (fileList.length > 0) {
        this.organizationCertImgfileList = [fileList[fileList.length - 1]];
      }
    },
    busRemovebankOpenAccountLicenceImg() {},
    busSuccessbankOpenAccountLicenceImg(response, file, fileList) {
      var that = this;
      this.bankOpenAccountLicenceImgfileList = fileList;
      if (response.code == 1) {
        this.form.bankOpenAccountLicenceImg = response.result.fileToken;
        this.form.bankOpenAccountLicenceImgUrl =
          response.result.url + '/image/256x';
      }
    },
    busErrorbankOpenAccountLicenceImg(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChangebankOpenAccountLicenceImg(file, fileList) {
      if (fileList.length > 0) {
        this.bankOpenAccountLicenceImgfileList = [
          fileList[fileList.length - 1]
        ];
      }
    }
  },
  created() {
    this.ongetcompanyintobusinesslicense();
  }
};
</script>
<style scoped>
[class*=' el-icon-'],
[class^='el-icon-'] {
  line-height: 20px;
}

h4.title {
  font-size: 16px;
  font-family: 'Microsoft Yahei';
  line-height: 180%;
  color: #333333;
  margin-bottom: 5px;
  float: left;
  margin-right: 10px;
}
.container >>> .el-form-item {
  margin-bottom: 8px;
}
.container >>> .el-form-item__label {
  font-size: 12px;
}
.container >>> .el-form-item__content {
  font-size: 12px;
  line-height: 30px;
  color: #666;
}
.container >>> .el-input__inner {
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  line-height: 32px;
}
.container >>> .el-input--prefix .el-input__inner {
  padding-left: 30px;
}
.container .form-describe-text {
  margin-left: 185px;
  color: #999;
  top: -9px;
  line-height: 20px;
  margin-top: 9px;
  position: relative;
  font-size: 12px;
}
.container >>> .el-button {
  padding: 8px 20px;
}
.container >>> .btn {
  border: 1px solid #c6e2ff;
  background-color: #31b7f4;
}
.btn:hover,
.btn-normal:hover {
  border: 1px solid;
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.form-error-text {
  margin-left: 185px;
  color: #ff3333;
  /* top: 0px; */
  line-height: 20px;
  margin-top: 5px;
  position: relative;
  font-size: 12px;
}
.container .upload-pop-desc {
  margin-left: 0;
  top: -5px;
}
.container >>> .el-upload--picture-card {
  width: 78px;
  height: 78px;
  line-height: 84px;
}
.container >>> .el-upload--picture-card img {
  width: 100%;
  height: 100%;
}
.container .el-upload__tip {
  margin-top: 0;
  line-height: 20px;
  color: #999;
}
.container >>> .el-form-item__error--inline {
  margin-left: 0;
}
.container >>> .el-checkbox.is-bordered.el-checkbox--mini {
  line-height: 18px;
}
.container >>> .el-upload-list--picture-card .el-upload-list__item {
  width: 78px;
  height: 78px;
}
.callout {
  border: 1px solid #f0d58f;
  background: #fffdf0;
  color: #333333;
  font-size: 12px;
  padding: 10px;
  line-height: 180%;
  position: relative;
  margin: 10px 0;
}
.callout .content {
  margin-left: 50px;
  padding: 10px 0;
}
</style>
